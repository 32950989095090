import BaseHeader from "@olmokit/components/Header";
import { I18nLinks } from "@olmokit/core/i18n/links";
import "components/TitleHome";
import { $all, $, forEach, addClass, removeClass, on } from "@olmokit/dom";
import "./index.scss";

/**
 * Component: Header
 *
 */

function dropdownAction($container, $dropdown, event: string) {
  forEach($container, (container, index) => {
    on(container, event, function(){
        forEach($dropdown, (dropdown, i) => {
          if(dropdown.id != "Dropdown_"+index){
            removeClass(dropdown, 'Dropdown:show');
          }
        })
        // console.log("#Dropdown_"+index)
        addClass($("#Dropdown_"+index), 'Dropdown:show');
      });
    });

}

export function Header() {
  const header = BaseHeader();
  I18nLinks();
  const $container = $all(".Header:nav__item");
  const $dropdown = $all(".Dropdown:");
  const $header = $("header");
  // console.log($container);
  // console.log($dropdown);

    dropdownAction($container, $dropdown,  "mouseover");
    dropdownAction($dropdown, $dropdown,  "mouseover");
    // dropdownAction(window, 'show', "onclick", removeClass);
    window.onclick = function (event) {
      forEach($dropdown, (drop, index) =>{
        removeClass(drop, 'Dropdown:show');
      });
    };

    window.onclick = function (event) {
      forEach($dropdown, (drop, index) =>{
        removeClass(drop, 'Dropdown:show');
      });
    };

    on($header, "mouseleave", event => {
      forEach($dropdown, (drop, index) =>{
        removeClass(drop, 'Dropdown:show');
      });
    })

  

  return {
    /**
     * Set variant
     *
     * @param {"light" | "dark"} variant
     */
    setVariant(variant) {
      const { className } = header.$root;
      header.$root.className = className.replace(
        /(is-).+(\s*)/,
        `$1${variant}$2`
      );
    },
  };
}
