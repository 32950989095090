import "./index.scss";
// import "../Contactform/index.scss"; //TODO: da risolvere nesletter
import "@olmokit/core/forms/checkbox";
import FormsInputMaterial from "@olmokit/core/forms/input/material";
import Olmoforms from "@olmokit/core/olmoforms/feedback";
import Dependency from "@olmokit/core/olmoforms/dependency";



/**
 * Component: FormContact
 *
 */
export function Newsletter() {

  const fillform = Olmoforms(".Newsletter:", {
    sent: (formData) => {
      dataLayer.push({
        ...formData,
        event: "form sent",
        form: "Form Newsletter",
      });
    },
  });

  Dependency(".Newsletter:");
  FormsInputMaterial();

  return {
    destroy() {
      fillform.destroy();
    },
  };
}