import "@olmokit/core/debug/api";
import "@olmokit/core/reboot/index.scss";
import "@olmokit/core/grid/index.scss";
import "@olmokit/core/typography/reset.scss";
import "@olmokit/core/icon/index.scss";
import { CookiesBanner } from "components/Cookies";
import LazyLoad from "@olmokit/core/lazy";
import "@olmokit/core/img";
import { Header } from "components/Header";
import "components/Footer";
import "components/Title";
import "components/TextBlock";
import "components/Image";
import "components/Text";
import "components/CbmImg";
// import "components/Relcanonical";
import "./index.scss";
import "utils/btn.scss";
import "utils/fonts.scss";
import "utils/logo.scss";
import { MenuMobile } from "components/MenuMobile";
import { Glidecontrol } from "components/Glidecontrol";


MenuMobile();
Header();
Glidecontrol();
CookiesBanner();
new LazyLoad();
